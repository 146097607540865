<template>
    <div class="ant-card" id="course_category">
        <a-spin v-if="$route.meta.isShow" :spinning="spinning" size="large">
            <a-tabs v-model="activeKey">
                <a-tab-pane :key="1" tab="每日新增-付费-复购_APP免费渠道报表">
                    <Free></Free>
                </a-tab-pane>
                <a-tab-pane :key="2" tab="每日新增-付费-复购_APP付费渠道报表">
                    <Pay></Pay>
                </a-tab-pane>
            </a-tabs>
        </a-spin>
        <router-view/>
    </div>
</template>

<script>
import Free from "./components/free"
import Pay from "./components/pay"
export default {
    components: {Free,Pay},
    data() {
        return {
            spinning: false, // 全局loading
            tableLoading: false,
            activeKey:1,
        };
    },
    
    methods: {
        // 回到顶部的函数
        targetFn() {
            return document.querySelector("#course");
        },
    }
};
</script>

<style lang="less" scoped>
#course_category {
    padding: 10px 20px;
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
}
</style>
