<template>
    <div>
        <div class="ant-card-body">
            <div class="head-box">
                <div class="table-operator">
                    <span class="item">
                        <a-date-picker  @change="registerChange" v-model="form.datePicker" placeholder="当日付费新增用户日期" style="width: 210px;"/>
                    </span>
                    
                    <span class="item">
                        <a-select ref="select" v-model="form.status" placeholder="进度状态" style="width: 210px;">
                            <a-select-option :value="0">全部</a-select-option>
                            <a-select-option :value="1">进行中</a-select-option>
                            <a-select-option :value="2">已完成</a-select-option>
                        </a-select>
                    </span>
                    
                    <span class="item">
                        <a-button class="margin_right10" type="primary" @click="searchClick">搜索</a-button>
                    </span>
                </div>
            </div>
            <div class="contant-box">
                <a-table ref="table" :columns="columns" :data-source="tableList" :row-key="record => record.userNo" :loading="tableLoading" :pagination="false" :scroll="{ x: 2500 }"></a-table>
            </div>
            <MyPagination :count="count" :pageNo="pageNo" @showSizeChangeFn="showSizeChangeFn" v-show="!tableLoading"/>
        </div>
    </div>
</template>

<script>
import MyPagination from "@/components/pagination/MyPagination";
import {UserList} from "@/request/api/userManage";
import {codeFn} from "@/utils/tools";
export default {
    components: {MyPagination},
    created() {
        if (codeFn("/adminv2/usercenter/lockUser")) this.isLockUser = true;
        if (codeFn("/adminv2/usercenter/userDetail")) this.isUserDetail = true;
        if (codeFn("/adminv2/usercenter/userList/export")) this.isExport = true;
        this.getTagList();
    },
    
    data() {
        return {
            isLockUser: false,
            isUserDetail: false,
            isExport: false,
            form:{
                registerDate:'',
                status:undefined,
                datePicker:'',
            },
            
            tableLoading: false,
            columns: [
                {
                    title: "日期",
                    dataIndex: "userName",
                    key: "userName",
                    fixed: "left",
                },
                {
                    title: "渠道名",
                    dataIndex: "nickName",
                    key: "nickName",
                    fixed: "left",
                    width:'150px',
                },
                {
                    title: "当日付费新增用户数",
                    dataIndex: "vipStatus",
                    key: "vipStatus",
                },
                {
                    title: "当日首购付费用户数",
                    dataIndex: "newestAgentName",
                    key: "newestAgentName",
                },
                {
                    title: "当日首购用户当日APP启动数",
                    dataIndex: "phoneBelong",
                    key: "phoneBelong",
                },
                {
                    title: "首购用户7日活跃用户数（排除首次启动行为）",
                    dataIndex: "source",
                    key: "source",
                },
                {
                    title: "首购用户7日复购用户数",
                    dataIndex: "registerTime",
                    key: "registerTime",
                },
                {
                    title: "首购用户15日复购用户数",
                    dataIndex: "lastPay",
                    key: "lastPay",
                },
                {
                    title: "首购用户30日复购用户数",
                    dataIndex: "totalGmv",
                    key: "totalGmv",
                },
                {
                    title: "首购用户30日复购率",
                    dataIndex: "totalCredit",
                    key: "totalCredit",
                },
                {
                    title: "首购用户30日订单数",
                    dataIndex: "totalCredit1",
                    key: "totalCredit1",
                },
                {
                    title: "首购用户30日GMV",
                    dataIndex: "totalCredit2",
                    key: "totalCredit2",
                },
                {
                    title: "首购用户30日人均LTV",
                    dataIndex: "totalCredit3",
                    key: "totalCredit3",
                },
            ],
            tableList: [],
            
            pageNo: 1, // 当前页
            pageSize: 20, // 每页条数
            count: 0,// 列表的总条数
        };
    },
    
    watch: {
        $route(to) {
            if (to.path === "/reportFormManage/transactionIndex") {
                this.getTagList();
            }
        },
    },
    
    methods: {
        // 搜索
        searchClick() {
            this.pageNo = 1
            this.getTagList()
        },
        
        // 点击重置
        resetClick(){
            this.pageNo = 1;
            this.getTagList()
        },
        
        // 搜索 - 注册时间
        registerChange(date, dateString){
            if (dateString[0] != "") {
                this.form.registerDate = dateString;
            } else {
                this.form.registerDate = "";
            }
        },
        
        // 分页功能切换的回调
        showSizeChangeFn(current, pageSize) {
            this.pageNo = current;
            this.pageSize = pageSize;
            this.getTagList();
        },
        
        // 列表数据
        getTagList() {
            this.tableLoading = true
            UserList({
                phone:this.form.phone,
                channel:this.form.channel == undefined ? '' : this.form.channel.toString(),
                registerSource:this.form.registerSource == undefined ? 0 : this.form.registerSource,
                courseName:this.form.courseName == undefined ? '' : this.form.courseName,
                vipStatus:this.form.vipStatus == undefined ? 0 : this.form.vipStatus,
                registerDate:this.form.registerDate,
                registerEndDate:this.form.registerEndDate,
                lastPayStart:this.form.lastPayStart,
                lastPayEnd:this.form.lastPayEnd,
                vipEnd:this.form.vipEnd == undefined ? 0 : this.form.vipEnd,
                gmv:this.form.gmval,
                sortField:!this.form.sortType ? '' : this.form.sortField,
                sortType:this.form.sortType,
                pageNo: this.pageNo,
                pageSize: this.pageSize,
            }).then((res) => {
                if (res.code === 200) {
                    this.tableList = res.data.list;
                    this.count = res.data.count;
                }
                this.tableLoading = false;
            });
        },
        
        // 下载
        isDownloadClick(){
            console.log('点击下载')
        },
    }
};
</script>

<style lang="less" scoped>
.ant-card-body{
    padding: 0;
}

.item{
    margin-right: 20px;
    margin-bottom: 10px;
}
#course_category {
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
}

.head-box {
    display: flex;
}

.avatar {
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-right: 10px;
}

.table-operator{
    display: flex;
    flex-wrap: wrap;
}
</style>
